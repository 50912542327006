@use '../../styles/theme';

.newProduct {
  height: 100%;
  padding: 24px 16px;
  overflow: auto;
  .product {
    background-color: #fff;
    padding: 16px;
    border-radius: 8px;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      span {
        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
    .participant {
      padding: 16px;
      border: 2px solid theme.$grey_1;
      border-radius: 8px;
      margin-left: 8px;
      margin-top: 8px;
      .price {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 8px;
        margin: 8px 0;
        align-items: center;
        .taxes {
          grid-column: span 2;
        }
      }
    }
  }
}
