@use '../../styles/_theme.scss';

$sidebarWidth: 40%;
$ordersSidebarWidth: min(1528px, 90%);

@keyframes slidein {
  from {
    transform: translateX(calc(100% + 20px));
  }

  to {
    transform: translateX(0%);
  }
}

.rightSidebar {
  position: fixed;
  right: 0px;
  top: 10px;
  bottom: 0;
  background-color: #f8f9fd;
  z-index: 10;
  transition: all 0.3s;
  width: 0px;
  min-width: 0px;
  height: calc(100vh - 20px);
  border-radius: 24px 0 0 24px !important;
  overflow: hidden;
  &.open {
    width: $sidebarWidth;
    min-width: 500px;
    animation: slidein 0.3s cubic-bezier(0.82, 0.085, 0.395, 0.895);
  }
  &.ordersOpen {
    width: $ordersSidebarWidth;
    min-width: 600px;
    overflow: hidden;
    animation: slidein 0.3s cubic-bezier(0.82, 0.085, 0.395, 0.895);
  }
  .wrapper {
    display: flex;
    flex-flow: column;
    overflow: hidden;
    height: 100%;
    background: theme.$white;
  }
}

.ctaBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: theme.$white;
  &:not(&.float) {
    padding: 16px;
    box-shadow: 0 2px 6px 0 rgb(0, 0, 0, 0.2);
  }
  &.float {
    margin-top: auto;
  }
}

@media only screen and (max-width: (991px)) {
  .rightSidebar {
    z-index: 999;
    height: unset;
    top: 0;
    &.open {
      width: $sidebarWidth;
      min-width: 100vw;
    }
  }
}
