.container {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0 4rem 0 0;
  margin-top: 1rem;
}

.content {
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 48px;
  width: 100%;
  padding: 0;
}
