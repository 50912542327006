@use '../../styles/_theme.scss';

.infoContainer {
  background-color: #fff;
  width: 100%;
  height: 20vh;
  margin-top: 24px;
  border-radius: 12px;
}

.infoContent {
  padding: 1.5rem 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
