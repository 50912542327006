@use '../../styles/_theme.scss';

.inputWrapper {
  width: 100%;
  .input {
    position: relative;
    width: 100%;
    &.error {
      .textfield {
        border: 2px solid theme.$red !important;
      }
    }
    .label {
      pointer-events: none;
    }
    .utilityIcon {
      position: absolute;
      right: 1.2rem;
      height: 100%;
      display: flex;
      align-items: center;
      background: unset;
      padding: 0;
      &:hover {
        background: unset;
      }
      &:focus {
        background: unset;
      }
      &:active {
        transform: scale(0.99);
      }
      &:disabled {
        background: unset;
        cursor: unset;
      }
      .uIcon {
        svg {
          * {
            stroke: #525255;
          }
        }
      }
    }
    .label {
      position: absolute;
      left: 1rem;
      top: 0.9rem;
      transition: top 0.2s;
      color: #8e8e93;
      &.focused {
        top: 0.4rem;
      }
    }
    .textfield {
      background: theme.$white;
      width: 100%;
      border-radius: 8px;
      border: 2px solid #d8d8dc;
      padding-left: 1rem;
      padding-top: 1.4rem;
      padding-bottom: 0.4rem;
      padding-right: 1rem;
      height: 48px;
      box-shadow: unset;
      filter: none;
      color: #242426;
      .-webkit-input-placeholder {
        color: #8e8e93;
      }
      &:focus {
        border: 2px solid #277cb4;
        outline: none;
      }
    }
  }
  .errorMessage {
    max-height: 0rem;
    height: fit-content;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    margin: 0;
    color: theme.$red;
    &.visible {
      max-height: 3rem;
    }
  }
}

input[type='text'],
input[type='tel'],
input[type='email'],
input[type='password'],
input[type='date'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  opacity: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type='date']::-webkit-date-and-time-value {
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.focused {
  top: -9px;
}
