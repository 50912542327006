.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 30rem;
  background-color: #6f6f6ff0;
  color: #fff;
  font-weight: bold;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  position: absolute;
  top: 2rem;
  right: 0;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
