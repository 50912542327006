@use '../../styles/theme';

.container {
  padding: 8px;
  background-color: theme.$grey_0;
  height: 100%;
  overflow: auto;
  .passholdersList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(380px, 100%), 1fr));
    gap: 16px;
  }
}
