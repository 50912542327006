@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.drawer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100vh;
  z-index: 10;
  display: flex;
  .modalOverlay {
    background: #242426;
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .modalBox {
    height: 100%;
    z-index: 2;
    background: #fff;
    max-width: 100%;
    max-height: 100%;
    position: relative;
    border-radius: 0 16px 16px 0;
    padding: 56px 24px 24px 24px;
    animation-name: slideInFromLeft;
    animation-duration: 0.2s;
    animation-timing-function: ease;
    .content {
      overflow: auto;
      max-width: 100%;
      max-height: 100%;
      height: 100%;
    }
    .closeBtn {
      position: absolute;
      right: 0;
      top: 0;
      padding: 16px 16px;
    }
  }
}
.buttonHidden {
  .modalBox {
    padding: 24px;
  }
  .closeBtn {
    display: none;
  }
}
