$primary: var(--primary, #003b4b);
$secondary: var(--secondary, #2b8bb0);
$primaryForeground: var(--primaryForeground, #ffffff);
$secondaryForeground: var(--secondaryForeground, #ffffff);

$contentBackgroundColor: var(--contentBackgroundColor, #f4f4f9);

$white: var(--white, #ffffff);
$black: var(--black, #000000);

$grey_0: var(--grey_0, #f4f4f9);
$grey_1: var(--grey_1, #d8d8dc);
$grey_2: var(--grey_2, #b2b2b6);
$grey_3: var(--grey_3, #8e8e93);
$grey_4: var(--grey_4, #5c5c5f);
$grey_5: var(--grey_5, #242426);

$green: var(--green, #098a00);
$green_disabled: var(--green_disabled, #e9f3e8);
$orange: var(--orange, #ee8600);
$orange_disabled: var(--orange_disabled, #fceede);
$red: var(--red, #e80003);
$blue: var(--blue, #3560da);
$yellow: var(--yellow, #f6ca1c);
$red_disabled: var(--red_disabled, #fee9e7);

$mobileS: 320px;
$mobileM: 375px;
$mobileL: 480px;
$tablet: 768px;
$laptop: 992px;
$desktop: 1200px;
