@use '../../styles/theme';

.dtaOrder {
  background: #fff;
  border-radius: 8px;
  padding: 16px;
  height: fit-content;
  .details {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    .detailsContent {
      width: 100%;
    }
    img {
      width: 100px;
    }
  }
  .toggleAccordion {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    outline-offset: 1px;
  }
  .accordion {
    background: theme.$grey_0;
    border-radius: 8px;
    padding: 8px;
    .keyValue {
      margin-left: 16px;
    }
  }
  .underline {
    text-decoration: underline;
    margin-left: 8px;
  }
  .labelGrey {
    color: #75757d;
  }
  span {
    word-break: break-all;
  }
  .items {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
    .item {
      border: 1px solid theme.$grey_3;
      border-radius: 8px;
      padding: 8px;
      .ticketItem {
        display: grid;
        grid-template-columns: 122px 1fr;
        gap: 8px;
        width: 100%;
        margin-bottom: 8px;
        .doubleColumn {
          grid-column: span 2;
        }
      }
    }
  }
}
