@use '../../styles/_theme.scss';

.block {
  margin: 0;
  .title {
    font-family: 'Manrope', 'sans-serif';
    padding: 16px 0;
  }
  .overviews {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 8px;
    .overview {
      border-radius: 5px;
      padding: 24px;
      background-color: theme.$white;
      font-family: 'Manrope', 'sans-serif';
      text-transform: uppercase;
      text-align: center;
      line-height: 1.2;
    }
  }
}
