@use '../../styles/_theme.scss';

.container {
  background: theme.$grey_0;
  border-radius: 12px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0.5rem 1rem;
}

.input {
  width: 3rem;
  text-align: center;
}
