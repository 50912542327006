@use '../../styles/theme';

.multiSelection {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 8px;
  margin-bottom: 1px;
  .list {
    border: 2px solid theme.$grey_1;
    height: 120px;
    overflow: auto;
    border-radius: 8px;
    button {
      padding: 4px 8px;
      width: 100%;
      text-align: left;
      cursor: pointer;
      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }
}
