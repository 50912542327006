@use '../../styles/_theme.scss';

.image {
  width: 122px;
  height: 150px;
  display: block;
  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
}
