@use '../../styles/_theme.scss';

.mapWrapper {
  position: relative;
}
.popup {
  z-index: 1;
  & * {
    border-radius: 12px;
  }
  & button {
    font-size: 2.3rem;
    width: 5rem;
    height: 4rem;
  }
}

.popupContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  & p {
    margin: 0.3rem;
  }
}

.mapControlsContainer {
  position: absolute;
  right: 80px;
  top: 20px;
  .mapControls {
    border-radius: 18px;
    padding: 10px;
    div {
      background: none;
      box-shadow: unset;
    }
    button {
      width: 40px;
      border: unset;
      height: 40px;
      border-radius: 12px;
      box-shadow: unset;
      &:first-of-type {
        margin-bottom: 8px;
      }
      &:last-of-type {
        display: none;
      }
    }
  }
}
