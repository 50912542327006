@use '../../styles/_theme.scss';

.container {
  padding: 16px;
  border-radius: 16px;
  background: theme.$white;
}

.contentInfo {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
}

.entries {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.label {
  font-weight: 700;
}
