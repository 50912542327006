@use '../../styles/_theme.scss';

$placeholderWidth: 126px;
$placeholderHeight: 182px;

.photo {
  width: $placeholderWidth;
  height: $placeholderHeight;
  display: block;
  border-radius: 8px;
  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
}
.placeholder {
  width: $placeholderWidth;
  height: $placeholderHeight;
  border-radius: 8px;
  background-color: theme.$grey_0;
  color: theme.$secondary;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
