@import '../../constants/theme';

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.wordWrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.h1 {
  font-weight: 800;
  font-size: 10rem;
  line-height: 10rem;
  -webkit-text-stroke: 0.25rem;
}

.h2 {
  font-weight: 800;
  font-size: 6rem;
  line-height: 6rem;
}

.h3-heavy {
  font-weight: 800;
  font-size: 3rem;
  line-height: 3.5rem;
}

.h3 {
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.5rem;
}

@mixin h4 {
  font-size: 2.5rem;
  line-height: 3.4rem;
}
.h4 {
  @include h4;
  font-weight: 600;
}
.h4-heavy {
  @include h4;
  font-weight: 800;
}
.h4-medium {
  @include h4;
  font-weight: 600;
}

@mixin p {
  font-size: 1.9rem;
  line-height: 2.5rem;
}
.p-heavy {
  @include p;
  font-weight: bold;
}
.p-medium {
  @include p;
  font-weight: 600;
}
.p-line {
  @include p;
  font-weight: 500;
}

.h900-medium,
.h900-heavy,
.h900-medium,
.h800-medium,
.h800-heavy,
.h700-medium,
.h700-heavy,
.h600-medium,
.h600-heavy,
.h500-medium,
.h500-heavy,
.h400-medium,
.h400-heavy,
.h300-medium,
.h300-heavy,
.h200-medium,
.h200-heavy,
.h100-heavy {
  font-family: 'Manrope', 'Helvetica', 'Arial', sans-serif;
}

@mixin h900 {
  font-size: 2.9rem;
  line-height: 3.5rem;
  @media (max-width: $laptop) {
    font-size: 2.8rem;
    line-height: 3.35rem;
  }
}

.h900-medium {
  @include h900;
  font-weight: 700;
}

.h900-heavy {
  @include h900;
  font-weight: 800;
}

@mixin h800 {
  font-size: 2.6rem;
  line-height: 3.1rem;
  @media (max-width: $laptop) {
    font-size: 2.5rem;
    line-height: 3rem;
  }
}

.h800-medium {
  @include h800;
  font-weight: 700;
}

.h800-heavy {
  @include h800;
  font-weight: 800;
}

@mixin h700 {
  font-size: 2.3rem;
  line-height: 2.75rem;
  @media (max-width: $laptop) {
    font-size: 2.2rem;
    line-height: 2.65rem;
  }
}

.h700-medium {
  @include h700;
  font-weight: 700;
}

.h700-heavy {
  @include h700;
  font-weight: 800;
}

@mixin h600 {
  font-size: 2rem;
  line-height: 2.4rem;
  @media (max-width: $laptop) {
    font-size: 1.9rem;
    line-height: 2.3rem;
  }
}

.h600-medium {
  @include h600;
  font-weight: 700;
}

.h600-heavy {
  @include h600;
  font-weight: 800;
}

@mixin h500 {
  font-size: 1.8rem;
  line-height: 2.15rem;
  @media (max-width: $laptop) {
    font-size: 1.7rem;
    line-height: 2.05rem;
  }
}

.h500-medium {
  @include h500;
  font-weight: 700;
}

.h500-heavy {
  @include h500;
  font-weight: 800;
}

@mixin h400 {
  font-size: 1.6rem;
  line-height: 2.4rem;
  @media (max-width: $laptop) {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
}

.h400-medium {
  @include h400;
  font-weight: 700;
}

.h400-heavy {
  @include h400;
  font-weight: 800;
}

@mixin h300 {
  font-size: 1.4rem;
  line-height: 2.1rem;
  @media (max-width: $laptop) {
    font-size: 1.3rem;
    line-height: 1.95rem;
  }
}

.h300-medium {
  @include h300;
  font-weight: 700;
}

.h300-heavy {
  @include h300;
  font-weight: 800;
}

@mixin h200 {
  font-size: 1.3rem;
  line-height: 1.55rem;
  @media (max-width: $laptop) {
    font-size: 1.2rem;
    line-height: 1.45rem;
  }
}

.h200-medium {
  @include h200;
  font-weight: 700;
}

.h200-heavy {
  @include h200;
  font-weight: 800;
}

.h100-heavy {
  font-weight: 800;
  font-size: 1.1rem;
  line-height: 1.3rem;
  @media (max-width: $laptop) {
    font-size: 1.2rem;
    line-height: 1.45rem;
  }
}

@media (max-width: $laptop) {
  .h1 {
    font-size: 4rem;
    line-height: 4rem;
  }

  .h2 {
    font-size: 3.8rem;
    line-height: 3.8rem;
  }

  .h3 {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  @mixin h4 {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  .h4,
  .h4-heavy,
  .h4-medium {
    @include h4;
  }

  @mixin p {
    font-size: 1.5rem;
    line-height: 2.1rem;
  }
  .p-heavy,
  .p-medium,
  .p-line {
    @include p;
  }
}
