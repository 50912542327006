@use '../../styles/_theme.scss';

.textInputContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  .formGroup {
    width: 100%;
    background: #f4f4f9;
    border-radius: 8px;
    label {
      color: #000000;
    }
    input {
      color: theme.$black;
      border: unset;
      font-family: 'Manrope', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    }
    .inputLabelContainer {
      width: 100%;
      margin-bottom: 5px;
    }
    fieldset {
      border: unset;
    }
    .clicable {
      cursor: pointer;
    }
  }
}
