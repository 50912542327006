@use '../../styles/_theme.scss';

$switchWidth: 48px;
$switchHeight: 24px;
$sliderSize: calc($switchHeight - 4px);

.switchLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: $switchWidth;
  height: $switchHeight;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
  .switchButton {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: $sliderSize;
    height: $sliderSize;
    border-radius: 45px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  }
  &:active {
    .switchButton {
      width: calc($sliderSize + 2px);
    }
  }
  &.checked {
    background-color: theme.$secondary;
    .switchButton {
      left: calc(100% - 2px);
      transform: translateX(-100%);
    }
  }
  &.unchecked {
    background-color: theme.$grey_2;
  }
  .switchCheckbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }
}
