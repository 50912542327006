@use '../../styles/theme';

.container {
  overflow-y: auto;
  padding: 16px 32px;
  background-color: theme.$grey_0;
  height: 100%;
  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .loading {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
