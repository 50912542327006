.defaultModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100vh;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;
  .modalOverlay {
    background: #242426;
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .modalBox {
    z-index: 2;
    background: #fff;
    overflow: auto;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 16px;
    padding: 56px 24px 24px 24px;
    .closeBtn {
      position: absolute;
      right: 0;
      top: 0;
      padding: 16px 16px;
    }
    .titleRow {
      display: flex;
      background-color: #fff;
      justify-content: flex-start;
      align-items: center;
      padding-left: 16px;
      min-height: 56px;
    }
  }
  .small {
    max-width: 480px;
    max-height: 480px;
  }
  .fixedTitle {
    background-color: #f4f4f9;
    padding: 0;
  }
}
.buttonHidden {
  .modalBox {
    padding: 24px;
  }
  .closeBtn {
    display: none;
  }
}
