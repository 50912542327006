@use '../../styles/_theme.scss';

.formGroup {
  position: relative;
  padding-bottom: 17px;
  .labelWrapper {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    label {
      display: inline-block;
      color: theme.$black;
      padding-bottom: 5px;
      margin-right: 5px;
    }
  }
  .input {
    width: 100%;
    height: auto;
    cursor: text;
    outline: currentcolor none 0px;
    border-radius: 2px;
    color: rgb(51, 55, 64);
    &:focus {
      border-color: rgb(120, 202, 255);
    }
  }
}
