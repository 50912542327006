@use '../../../styles/theme';

$sidebarWidth: 230px;

$transitionDuration: 0.2s;
$spaceBetweenItems: 24px;
$spaceBetweenElements: 16px;
$spaceBetweenCategories: 2px;

$boxPaddingVertical: 16px;
$boxPaddingHorizontal: 8px;
$boxPadding: $boxPaddingVertical $boxPaddingHorizontal;
$collapsedCellWidth: 56px;
$collapsedSideBarWidth: calc($collapsedCellWidth + calc($boxPaddingHorizontal * 2));

.sidebar {
  position: sticky;
  top: 0px;
  transition-duration: $transitionDuration;
  transition-property: max-width, min-width;
  max-width: $sidebarWidth;
  min-width: $sidebarWidth;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: $boxPadding;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  box-shadow: -4px 0 5px #333;
  &::-webkit-scrollbar {
    display: none;
  }
  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: $spaceBetweenItems;
    .mainLogo {
      padding: 0;
      display: block;
      height: 55px;
      transition-duration: $transitionDuration;
      transition-property: height;
      margin-bottom: $spaceBetweenElements;
      cursor: pointer;
    }
    .channel {
      transform-origin: top left;
      transform: scale(1);
      opacity: 1;
      max-height: 0px;
      transition-duration: $transitionDuration;
      transition-property: transform, opacity;
    }
  }
  .routes {
    display: flex;
    flex-direction: column;
    .subitemWrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .popupWrapper {
        position: absolute;
        left: calc(100% + 16px);
        z-index: 1;
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
      }
      .popup {
        background: white;
        border-radius: 8px;
        padding: 16px;
        width: fit-content;
      }
    }
    .route {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: $collapsedCellWidth;
      padding: 8px;
      border-radius: 8px;
      color: theme.$grey_5;
      margin-bottom: $spaceBetweenCategories;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background: rgba(0, 0, 0, 0.05);
        }
      }

      cursor: pointer;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      text-decoration: none;
      &.subitem {
        .label {
          padding-left: 34px;
        }
      }
      &.withSubitems {
        &.selected {
          background-color: rgba(0, 0, 0, 0.03);
          color: theme.$grey_5;
        }
      }
      &.selected {
        //@to-do aramon styles demo
        color: #cb3251;
        background: rgba(0, 0, 0, 0.06);
      }
      .icon {
        margin-left: 10px;
      }
      .label {
        padding-left: 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        transition-duration: $transitionDuration;
        transition-property: padding-left, width;
      }
      & > span {
        margin: 0 3px 0 0px;
        padding: 0 0 0 13px;
      }
    }
  }
  .collapseBtn {
    height: 40px;
    width: 100%;
    border-radius: 8px;
    cursor: pointer;
    background-color: #fff;
    border: 2px solid theme.$grey_1;
    color: theme.$grey_4;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: $transitionDuration;
    transition-property: left;
  }
  .user {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    z-index: 1;
    margin-bottom: 10px;
    margin-top: auto;
    padding-left: $boxPaddingHorizontal;
    transition-duration: $transitionDuration;
    transition-property: margin-bottom;
    & > div {
      min-width: 40px; //@to-do add to spotlio components?
    }
    .userName {
      padding-left: 8px;
      text-transform: capitalize;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .logout {
      margin-left: auto;
      opacity: 1;
      transition-duration: $transitionDuration;
      transition-property: opacity;
      cursor: pointer;
    }
  }
  .logoutCollapsed {
    display: flex;
    justify-content: center;
    width: $collapsedCellWidth;
    margin-right: auto;
    margin-top: -20px;
    opacity: 0;
    pointer-events: none;
    z-index: 1;
    transition-duration: $transitionDuration;
    transition-property: margin-top, opacity;
    cursor: pointer;
  }
}

.sidebar {
  &.collapsed {
    max-width: $collapsedSideBarWidth;
    min-width: $collapsedSideBarWidth;
    .header {
      .mainLogo {
        height: 30px;
      }
      .channel {
        transform: scale(0);
        opacity: 0;
      }
    }
    .user {
      margin-bottom: $spaceBetweenElements;
      .logout {
        opacity: 0;
        pointer-events: none;
      }
    }
    .logoutCollapsed {
      justify-content: center;
      margin-top: 0px;
      opacity: 1;
      margin-bottom: 19px;
      pointer-events: inherit;
    }
    .collapseBtn {
      width: 100%;
    }
  }
}

.sidebarBtn {
  display: none;
}

@media only screen and (max-width: theme.$laptop) {
  .sidebar {
    display: none;
  }
  .sidebarBtn {
    display: block;
    width: fit-content;
    margin-right: auto;
    background: #fff;
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 20px;
    cursor: pointer;
  }
}

.sidebarModalWrapper {
  height: 100%;
  .sidebar {
    display: flex;
    height: 100%;
    .collapseBtn {
      display: none;
    }
  }
}

.flex {
  display: flex;
}
