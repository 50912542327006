@use '../../styles/_theme.scss';

.menuWrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .contentWrapper {
    display: flex;
    min-height: calc(100vh - 78px);
    background-color: theme.$white;
    overflow: auto;
  }
}

.contentWrapper {
  &.modal {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.optionWrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  overflow: auto;
  background-color: theme.$contentBackgroundColor;
  .contentWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    max-height: 100%;
    flex: 1;
    padding: 16px 32px;
    position: relative;
    &.contentWrapper::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &.contentWrapper::-webkit-scrollbar-track {
      background-color: rgba(255, 255, 255, 0.5);
    }
    &.contentWrapper::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.25);
      border-radius: 20px;
    }
  }
  .containerRoot {
    display: flex;
    flex-direction: column;
    padding: 0px;
    height: 100%;
    &::after {
      content: ' ';
      min-height: 20px;
    }
  }
}

.modalWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .contentWrapper {
    outline: none;
    height: calc(100vh - 78px);
    width: calc(100vw - 78px);
    background-color: theme.$white;
    border-radius: 5px;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media only screen and (max-width: (991px)) {
  .menuWrapper {
    .contentWrapper {
      align-items: inherit;
    }
  }

  .optionWrapper {
    max-height: unset;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 8px;
    .contentWrapper {
      display: flex;
      height: calc(100vh - calc(78px + 60px));
      min-height: calc(100vh - calc(78px + 60px));
      background-color: theme.$white;
      overflow: auto;
    }
  }
}
