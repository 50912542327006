.loadingAnimation {
  .dots {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    &.overlay {
      background: rgba(0, 0, 0, 0.2);
    }
    .logoWrapper {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .logoInnerWrapper {
        position: relative;
        img.logo {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          max-width: 200px;
          max-height: 200px;
        }
      }
    }
  }
}
