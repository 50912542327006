@use 'styles/theme';

.itemMenuIconContainer {
  display: flex;
  justify-content: center;
  text-decoration: none;
  border-radius: 10px;
  align-items: center;
  padding: 10px;
  border: 2px solid #bd2544;
  background-color: #ffffff;
}
