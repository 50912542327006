.textareaWrapperBackend {
  width: 100%;

  .textarea {
    border-radius: 12px;
    width: 100%;
    min-height: 110px;
    font-family: 'Manrope', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    outline: none !important;
    resize: none;
    border: unset;
    box-shadow: unset;
    padding: 16px;
  }
}
