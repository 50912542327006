@use 'styles/theme';

@keyframes slideInFromLeft {
  0% {
    right: -100%;
  }
  100% {
    right: 0%;
  }
}

.drawer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100vh;
  z-index: 10;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(12, 1fr);
  gap: 8px;
  padding-left: 32px;
  .box {
    display: flex;
    flex-direction: column;
    z-index: 1;
    background: theme.$grey_0;
    height: 100%;
    border-radius: 24px 0 0 24px;
    overflow: hidden;
    position: relative;
    animation-name: slideInFromLeft;
    animation-duration: 300ms;
    animation-timing-function: ease;
    min-width: 640px;
    &.small {
      grid-column: 8 / 13;
    }
    &.medium {
      grid-column: 3 / 13;
    }
    .header {
      padding: 32px;
      background-color: theme.$white;
      display: flex;
      flex-direction: row;
      align-items: center;
      &.tabs {
        padding-bottom: 0;
      }
      .secondary {
        margin-left: auto;
      }
      .main {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
    .footer {
      margin-top: auto;
      padding: 24px 32px;
      background-color: theme.$white;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .overlay {
    background: #242426;
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
