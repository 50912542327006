@use '../../styles/_theme.scss';

.alertDialogContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .alertDialogTitle {
    width: 100%;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 24px;
  }
  .alertDialogButtonsContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 24px;
    //@to-do aramon styles demo
    & > button {
      background: #cb3251;
      &:hover {
        background: #bd2544 !important;
      }
    }
  }
}
