.unit {
  width: max-content;
}

.reverseUnit {
  display: flex;
  flex-direction: row-reverse;
  width: max-content;
}

.unitMarginRight {
  margin: 0 0 0 0.2rem;
}

.unitMarginLeft {
  margin: 0 0.2rem 0 0;
}

.bold {
  font-weight: bold;
}

.unitMarginBold {
  font-weight: bold;
}

.right {
  display: flex;
  justify-content: flex-end;
}

.center {
  display: flex;
  justify-content: center;
}

.left {
  display: flex;
  justify-content: flex-start;
}
