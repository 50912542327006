.accordion {
  overflow: hidden;
  transition-property: max-height, max-width;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  &.responsive {
    overflow: hidden;
    transition-property: max-height, max-width;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 999rem;
    &.collapsed {
      max-height: 0;
      transition-property: max-height, max-width;
      transition-duration: 0.35s;
      transition-timing-function: cubic-bezier(0, 1, 0, 1);
    }
  }
}
