@use '../../styles/_theme.scss';

.editTabsContainer {
  flex-direction: row;
  display: flex;
  width: 100%;
  padding: 0px 16px 16px 16px;
  background: theme.$white;
  overflow-x: auto;
  overflow-y: hidden;
}
