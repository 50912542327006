.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  .titleAndTotalContainer {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    .title {
      margin-bottom: 2px;
      display: block;
    }
  }
}
.totalContainer {
  display: flex;
  flex: 1;
  align-items: center;
  margin-bottom: 10px;

  .total {
    display: flex;
    flex-direction: row;
    gap: 0.2rem;
    align-items: center;
    color: rgb(120, 126, 143);
  }
  .boldTotal {
    color: #111;
  }
}
