.limitSelectorContainer {
  overflow: visible;
  display: flex;
  align-items: center;
  border-radius: 12px;
  justify-content: space-between;
  padding: 6px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  .root {
    background-color: unset;
    overflow: visible;
    padding-right: 5px;
    font-family: 'Manrope', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  }
}
