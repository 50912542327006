@use '../../styles/theme';

.selectWrapper {
  position: relative;
  .select {
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    padding: 0 16px;
    background: theme.$white;
    border: 2px solid theme.$grey_1;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    .placeholder {
      color: theme.$grey_3;
    }
  }
  .dropdownWrapper {
    position: absolute;
    top: -8px;
    z-index: 1;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.08));
    .dropdown {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: flex-start;
      background: theme.$white;
      padding: 16px;
      border-radius: 8px;
      & button {
        padding: 8px;
        border-radius: 8px;
        width: 100%;
        text-align: left;
        cursor: pointer;
        @media (hover: hover) and (pointer: fine) {
          &:hover {
            background: rgba(0, 0, 0, 0.08);
          }
        }
      }
    }
  }
}
