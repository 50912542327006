@use '../../../styles/_theme.scss';

.header {
  width: 100%;
  height: 78px;
  max-height: 78px;
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: theme.$white;
  padding: 18px 24px;
  border-bottom: 1px solid #ebebf0;
  .dashboardButton {
    border: 1px solid theme.$black;
    cursor: pointer;
    border-radius: 5px;
    padding: 10px;
  }
  .loggedUser {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    cursor: pointer;
    user-select: none;
    img {
      max-width: 40px;
      max-height: 40px;
    }
    .userData {
      margin-left: 8px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      span {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      .greySpan {
        color: #bcbcc0;
      }
    }
    .userMenu {
      transform-origin: top;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      margin-top: 70px;
      border-radius: 4px;
      background-color: theme.$white;
      overflow: hidden;
      transition: all 0.3s;
      max-height: 200px;
      box-shadow: 1px 0px 10px 2px rgba(0, 0, 0, 0.05);
      &.collapsed {
        transition: all 0.3s;
        max-height: 0px;
        box-shadow: none;
      }
      .option {
        padding: 16px 20px;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        &:not(:last-child) {
          border-bottom: 1px solid #e0e0e0;
        }
        &:hover {
          color: theme.$white;
        }
      }
    }
  }
}
