@use '../../styles/_theme.scss';

.container {
  width: 100%;
  padding: 0;
  border-bottom: 1px solid theme.$grey_1;
}

.title {
  text-align: center;
}
