@use '../../styles/_theme.scss';

.container {
  display: flex;
  padding: 16px;
  border-radius: 16px;
  background: theme.$white;
  height: fit-content;
  .image {
    margin-right: 16px;
  }
  .title {
    margin-bottom: 8px;
    text-transform: capitalize;
  }
  .details {
    display: flex;
    flex-direction: column;
    color: theme.$grey_3;
  }
}
