@use '../../styles/theme';

.ql-toolbar {
  background: theme.$grey_0;
  border-radius: 8px 8px 0 0;
  border: 2px solid theme.$grey_1;
}
.ql-container {
  border-radius: 0 0 8px 8px;
  border: 2px solid theme.$grey_1;
  min-height: 160px;
}
