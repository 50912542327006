.input {
  border-radius: 12px;
  padding: 0px;
  button {
    padding: 5px;
  }
  input {
    padding: 14px;
    padding-right: 0px;
    padding-left: 0px;
  }
  fieldset {
    border: unset;
  }
}
