.pulse {
  width: 1rem;
  height: 1rem;
  background: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, var(--color) 50%);
  border-radius: 50%;
  color: #fff;
  text-align: center;
  animation: animate 2s linear infinite;
}
@keyframes animate {
  0% {
    box-shadow: 0 0 0 0 var(--color), 0 0 0 0 var(--color);
  }
  40% {
    box-shadow: 0 0 0 10px rgba(255, 0, 64, 0), 0 0 0 0 var(--color);
  }
  80% {
    box-shadow: 0 0 0 10px rgba(255, 0, 64, 0), 0 0 0 3px rgba(255, 0, 64, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 0, 64, 0), 0 0 0 3px rgba(255, 0, 64, 0);
  }
}
