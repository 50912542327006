.container {
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-weight: bold;
  text-align: center;
  border: 2px solid currentColor;
}

.container:hover {
  cursor: pointer;
}

.containerWithoutHover {
  padding: 8px 16px;
  border-radius: 16px;
  font-weight: bold;
  text-align: center;
}
