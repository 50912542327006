@use '../../styles/_theme.scss';

.tableContainer {
  width: 100%;
  .table {
    display: table;
    border-spacing: 0px;
    width: 100%;
    text-align: left;
    .headerCell {
      font-weight: 900;
      flex-direction: row;
      padding: 1rem 0.5rem;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    & svg {
      stroke: theme.$secondary;
    }

    .contentRow {
      cursor: pointer;
      &.selected {
        background-color: #f0f0f0;
        .cell {
          background-color: unset;
        }
      }
      &:hover {
        .cell {
          background-color: #f7f8f8;
        }
      }
    }
  }
}

.neutralTable {
  .table {
    .headerCell {
      padding: 0.5rem;
    }
    .headerColumn {
      background-color: transparent;
      border-top: 0 none;
      &:first-child {
        border-top: 0 none;
        border-left: 0 none;
      }
      &:last-child {
        border-top: 0 none;
        border-right: 0 none;
      }
    }
    tbody {
      tr:nth-child(odd) {
        background-color: #f7f8f8;
        td {
          background-color: transparent;
        }
      }
    }
    tr {
      td {
        &:first-child {
          border-left: 0;
        }
        &:last-child {
          border-right: 0;
        }
      }
      &:last-child {
        td {
          border-bottom: 0;
          &:first-child,
          &:last-child {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
          }
        }
      }
    }
  }
}

.cell {
  background-color: theme.$white;
  padding: 16px 8px;
}

.loading {
  position: absolute;
  background-color: theme.$white;
  opacity: 0.5;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.image {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.tableActionsContainer {
  display: flex;
  div {
    margin-right: 16px;
  }
}

.tableInputSelector {
  width: max-content;
}

.checkboxCustomContainer {
  padding: 10px;
}

.referenceLinkContainer {
  width: fit-content;
  padding: 5px;
  span {
    font-weight: bold;
  }
}

.completed {
  color: green !important;
}
.email {
  color: #2b8bb0;
  text-decoration: underline;
  font-weight: bold;
}
.passholder {
  color: #2b8bb0;
  font-weight: bold;
}
