@use '../../styles/_theme.scss';

.mainButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: theme.$red;
  height: 44px;
  border-radius: 6px;
  margin-top: 20px;
  span {
    color: theme.$white;
  }
}

.title {
  width: 100%;
  text-align: center;
}

.container {
  padding: 24px;
  border-radius: 14px;
}

.mainContainer {
  border-radius: 14px;
  .MuiPaper-rounded {
    border-radius: 14px !important;
  }
}

.dialog {
  border-radius: '14px';
  width: '100%';
}
