@use '../../styles/_theme.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border: 2px solid theme.$primary;
  border-radius: 1rem;
  gap: 8px;
  min-width: 85px;
  &:hover {
    cursor: pointer;
    background-color: theme.$grey_0;
  }
}
