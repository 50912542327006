@use '../../styles/_theme.scss';

.labelRange {
  padding-right: 8px;
  color: #242426;
}
.fullInput {
  padding: 16px;
  border-radius: 8px;
  border: none;
}
